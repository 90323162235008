import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Shaka from "../components/Likes/Shaka"
// Material UI Kit
import GridContainer from "../components/Grid/GridContainer"
import Card from "../components/Card/Card"
import CardHeader from "../components/Card/CardHeader"
import GridItem from "../components/Grid/GridItem"
// Styles
import withStyles from "@material-ui/core/styles/withStyles"
import javascriptStyles from "../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
  message: {
    textAlign: "center",
    padding: "50px",
    color: "#626f78",
  },
  shaka: {
    height: "75px",
    marginTop: "40px",
  },
}

const ThankYouPage = props => {
  const { classes } = props
  return (
    <Layout>
      <SEO title="Newletter Subscription Success" />
      <GridContainer
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ padding: "1.5rem 16px" }}
      >
        <Card style={style.cardBorder}>
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h3 className={classes.cardTitleWhite}>
              Thank you for subscribing to our newsletter!
            </h3>
          </CardHeader>
          <GridContainer>
            <GridItem className={classes.mlAuto} style={style.message}>
              <h3>
                We are pumped that you have allowed us to send you periodic
                emails with awesome info on projects, builds, boats a d killer
                stories. We value this gesture and promise that we will do our
                best to send the highest quality content your way!!
              </h3>
              <div>
                <Shaka shaka={true} styles={style.shaka} />
              </div>
            </GridItem>
          </GridContainer>
        </Card>
      </GridContainer>
    </Layout>
  )
}
export default withStyles(javascriptStyles)(ThankYouPage)
