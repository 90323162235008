import React from "react"
import shakaOpen from "./assets/shaka_open.svg"
import shakaClosed from "./assets/shaka_closed.svg"

const style = {
  image: {
    height: "30px",
    paddingRight: "9px",
  },
}

export default function Shaka(props) {
  const { shaka, styles } = props

  return (
    <div>
      {shaka ? (
        <img src={shakaClosed} alt={""} style={styles ? styles : style.image} />
      ) : (
        <img src={shakaOpen} alt="" style={styles ? styles : style.image} />
      )}
    </div>
  )
}
